import React from 'react';
import { ReactTyped } from "react-typed";

const queryStrings = [
  "I lost my job, what should I do?",
  "My GF broke up with me, how can I move on?",
  "Jesus, I feel lost, how can I find peace?",
  "How can I be happy?",
  "How can I be successful?",
];

const SearchBar = () => {
  return (
    <div className="flex justify-center items-center mt-10">
      <div className="relative md:w-4/5">
        <input
          type="text"
          className="w-full py-3 px-4 pr-12 text-xl border-2 border-gray-300 rounded-full"
          readOnly
        />
        <ReactTyped
          strings={queryStrings}
          typeSpeed={50}
          backSpeed={30}
          backDelay={1500}
          loop
          className="absolute font-extrabold left-4 top-3 text-xs md:text-xl text-primary-dark"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg className="h-6 w-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
      </div>
    </div>
  );
};

export default SearchBar;
