import React from 'react';

const Footer: React.FC = () => {
  return (
    <>
    <div className="bg-gray-100 py-16 px-8">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-2xl font-extrabold text-primary-dark mb-6">Subscribe to receive our latest updates!</h2>
        <div className="flex justify-center items-center mb-6">
          <input
            type="email"
            placeholder="Your email"
            className="w-full max-w-md p-3 border border-gray-300 rounded-l-full focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-transparent"
          />
          <button className="bg-primary-dark text-white px-6 py-2 md:py-3 rounded-r-full text-xs md:text-lg focus:outline-none focus:ring-2">
            Subscribe to updates
          </button>
        </div>
        <p className="text-gray-600 mb-1">we are also happy to hear you at <a href="mailto:hello@lyfe.ltd" className="text-primary-dark">support@talk2god.in</a></p>
        <p className="text-gray-600">Your email is 100% confidential and we won't send you any spam. Guaranteed.</p>
      </div>
    </div>
    <div className="text-center mt-8 mb-8">
      <p className="text-gray-500">Talk2God 2024. All rights reserved. <a href="https://sites.google.com/view/talk-to-god-privacy-policy/home" className="text-primary-dark">Privacy policy</a></p>
    </div>
    </>
  );
};

export default Footer;
