import React from 'react';

const FeatureSection: React.FC = () => {
  return (
    <div className="bg-white py-16 px-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 flex justify-center md:justify-start mb-8 md:mb-0">
          <img src="images/feature-1.png" alt="App Mockup" className="md:w-[30vw] h-auto" />
        </div>
        <div className="md:w-1/2 md:pl-12">
          <div className="mb-20">
            <div className="flex items-center mb-4">
              <img src="images/pocket_icon.png" alt="Pocket Icon" className="w-20 h-20 mr-4" />
                <p className="font-poppins font-medium"> 
                  <span className="text-primary-dark font-semibold">Seek guidance</span> on your life questions and understand their impact on your well-being with wisdom from the <span className="text-primary-dark font-semibold">Bible, right from your pocket.</span>
                </p>
            </div>
          </div>
          <div>
            <div className="flex items-center mb-4">
              <img src="images/ai_icon.png" alt="Pocket Icon" className="w-20 h-20 mr-4" />
                <p className="font-poppins font-medium"> 
                  AI-powered analysis of your queries, with <span className="text-primary-dark font-semibold">generative AI trained on biblical knowledge</span> to provide tailored answers and guidance.
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
