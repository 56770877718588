import React from 'react';

const FeatureSection2: React.FC = () => {
  return (
    <div className="bg-white py-16 px-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
        <div className="md:order-2 md:w-1/2 flex justify-center md:justify-start md:ml-10 mb-8 md:mb-0">
          <img src="images/feature-2.png" alt="App Mockup" className="md:w-[30vw] h-auto" />
        </div>
        <div className="md:w-1/2 md:pl-12">
          <div className="mb-20">
            <div className="flex items-center mb-4">
              <img src="images/app_logo.png" alt="Pocket Icon" className="w-20 h-20 mr-4" />
                <p className="font-poppins font-medium"> 
                   <span className="text-primary-dark font-semibold">The smartest personal guide of the Bible</span> that understands your questions, offering tailored, scripture-based answers using Natural Language Processing to help you feel better.
                </p>
            </div>
          </div>
          <div>
            <div className="flex items-center mb-4">
              <img src="images/press_icon.png" alt="Pocket Icon" className="w-20 h-20 mr-4" />
                <p className="font-poppins font-medium"> 
                  <span className="text-primary-dark font-semibold">One tap</span> to get all your queries answered directly from the Bible.
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection2;
