import React from 'react';

import { motion } from "framer-motion";
import SearchBar from './SearchBar';

const HeroSection: React.FC = () => {
  return (
    <div className="bg-white py-16 md:px-[19vw] overflow-hidden md:overflow-visible mt-3 md:mt-[100px]">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row">
        <div className='flex flex-col items-center order-2 md:order-1 md:items-start md:max-w-[30vw]'>
          <h1 className="text-2xl mt-10 md:mt-0 md:text-4xl font-bold font-poppins text-primary mb-4">
            Talk2God - Find Peace
          </h1>
          <p className="text-l px-3 md:px-0 font-poppins font-extrabold text-secondary-dark mb-3">
            An AI-powered app providing real-time, voice-based guidance from the Bible to help you navigate life's challenges
          </p>
          <div className="flex justify-center items-center space-x-4 mb-6">
            <a href="https://apps.apple.com/us/app/talk-to-god-find-peace/id6504428822" className="w-32">
              <img src="images/apple_store.svg" alt="Download on the App Store" className="w-full" />
            </a>
            <a 
            href="#"
            className="w-32 opacity-50 cursor-not-allowed"
            data-twe-toggle="tooltip"
            title="Coming soon!"
            >
              <img src="images/google_store.svg" alt="Get it on Google Play" className="w-full" />
            </a>
          </div>
        </div>
       <div className='flex flex-col order-1 md:order-2 justify-start ml-[10vw]'>
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 2, ease: "easeInOut" }}
            className="relative"
          >
          <img
            alt="Jesus blessing a person"
            src="images/blessing.png"
            className='w-[400px]'
          />
          <motion.div
            className="absolute inset-0 bg-primary-light opacity-75 rounded-full"
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: [0, 0.5, 0], scale: [1, 1.5, 1] }}
            transition={{ repeat: Infinity, duration: 3, ease: "easeInOut" }}
            style={{ filter: "blur(15px)" }}
          />
          </motion.div>
      </div>
      </div>
      <div className='mt-[5vh] md:mt-[20vh]'>
        <SearchBar />
      </div>
    </div>
  );
};

export default HeroSection;
