import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import FeatureSection from './components/FeatureSection';
import TextFeature from './components/TextFeature';
import FeatureSection2 from './components/FeatureSection2';
import FeatureSection3 from './components/FeatureSection3';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <TextFeature />
      <FeatureSection />
      <FeatureSection2 />
      <FeatureSection3 />
      <Footer />
    </>
  );
}

export default App;
