import React from "react";

const TextFeature: React.FC = () => {
  return (
    <div className="flex justify-center items-center mt-5 md:mt-[100px]">
      <p className="font-poppins text-xl font-extrabold px-3 md:px-0 md:w-1/3 text-center">
        This <span className="text-primary-dark">AI-powered</span> app that uses <span className="text-primary-dark">biblical</span> teachings to provide <span className="text-primary-dark">real-time, voice-based guidance</span>. It analyzes your queries and offers scripture-based insights to help you navigate life's challenges.
      </p>
    </div>
  )
};

export default TextFeature;