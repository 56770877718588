import React, { useState } from 'react';

import { motion } from "framer-motion";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  ViberShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  ViberIcon,
  TelegramIcon,
} from 'react-share';

const SHARE_URL = "https://apps.apple.com/us/app/talk-to-god-find-peace/id6504428822"
const TITLE = `I've found a source of comfort and answers with 'Talk To God'. 
It's an app where you can ask questions and receive responses based on the Bible and the teachings of Lord Jesus🙏📖✨. 
Download from here`
const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);

  const toggleShareMenu = () => setIsShareOpen(!isShareOpen);


  return (
    <nav className="bg-white sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between md:justify-center md:gap-[40vw] h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              src='images/app_logo.png' alt="logo" className='block w-20' />
              <motion.p 
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 100 }}
              className="ml-2 text-xl font-semibold font-poppins text-secondary">Talk2God</motion.p>
            </div>
          </div>
         <div className="hidden md:ml-6 md:flex md:space-x-8 relative">
            <div className='flex flex-col justify-center items-center'>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                type="button"
                onClick={toggleShareMenu}
                className={
                  `inline-block rounded bg-primary-dark px-6 pb-2 pt-1 text-xs font-poppins uppercase leading-normal text-white shadow-primary-3`
                }>
                Share with Friends
              </motion.button>
              {isShareOpen && (
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 100 }}
                  className="absolute top-full w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 flex justify-center"
                >
                  <div className="py-3 flex justify-around w-full">
                    <WhatsappShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <ViberShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <ViberIcon size={32} round={true} />
                    </ViberShareButton>
                    <TelegramShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <FacebookShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="bg-gray-200 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <div className='flex justify-center items-center'>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                type="button"
                onClick={toggleShareMenu}
                className={
                  `inline-block rounded bg-primary-dark px-6 pb-2 pt-1 text-xs font-poppins uppercase leading-normal text-primary-light shadow-primary-3`
                }>
                Share with Friends
              </motion.button>
              {isShareOpen && (
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 100 }}
                  className="absolute top-full w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 flex justify-center"
                >
                  <div className="py-3 flex justify-around w-full">
                    <WhatsappShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <ViberShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <ViberIcon size={32} round={true} />
                    </ViberShareButton>
                    <TelegramShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <FacebookShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={SHARE_URL} title={TITLE} className="flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full">
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                  </div>
                </motion.div>
              )}
            </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
